import theme from 'styles/themes/default';

type Props = {
  'data-test'?: string;
  className?: string;
  color?: string;
  height?: string;
  onClick?: () => void;
  width?: string;
};

function Share({
  color = theme.colors.gray['500'],
  height = '23',
  width = '16',
  ...props
}: Props) {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 16 23"
      width={width}
      {...props}
    >
      <path
        d="M8.33472 0.428615C8.13428 0.201808 7.77979 0.203876 7.58179 0.433017L4.25146 4.28926C3.8772 4.72267 3.92627 5.37638 4.36133 5.74938C4.79614 6.12237 5.45215 6.0734 5.82642 5.63999L7 4.28118V14.1482C7 14.7005 7.44775 15.1482 8 15.1482C8.55225 15.1482 9 14.7005 9 14.1482V4.31299L10.1814 5.64912C10.5608 6.07814 11.2173 6.11946 11.6479 5.74143C12.0784 5.36339 12.1199 4.70915 11.7405 4.28013L8.33472 0.428615Z"
        fill={color}
      />
      <path
        d="M0 9.06873C0 8.03722 0.84082 7.21605 1.86035 7.21605H4C4.55225 7.21605 5 7.66377 5 8.21605C5 8.76834 4.55225 9.21605 4 9.21605H2V21H14V9.21605H12C11.4478 9.21605 11 8.76834 11 8.21605C11 7.66377 11.4478 7.21605 12 7.21605H14.1401C15.1594 7.21605 16 8.03759 16 9.06873V21.1478C16 22.1788 15.1592 23 14.1401 23H1.86035C0.841309 23 0 22.1792 0 21.1478V9.06873Z"
        fill={color}
      />
    </svg>
  );
}

export default Share;
